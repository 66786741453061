import { useQuery } from "react-query";
import { getData } from "./api";

const PROJECT_PARAM = "Proxectos";

export const useGetProjects = () => {
    const { data, isSuccess, refetch } = useQuery({
        queryKey: ['getProjects'],
        queryFn: () => getData(PROJECT_PARAM),
        enabled: false
    });

    return { projects: data, isSuccess, refetch };
}