
import { useQuery } from "react-query";
import { getData } from "./api";
import { useGetProjects } from "./useGetProjects";

const PROJECT_PARAM = "Proxectos";

export const useGetProject = ({ projectId }) => {
    const { projects, isSuccess: isProjectsSuccess } = useGetProjects();

    const getProject = async () => {
        if (isProjectsSuccess) {
            const project = projects.find(project => project.id === projectId);
            if (project) {
                return project;
            }
        }

        return getData(PROJECT_PARAM, projectId);
    }

    const { data, isSuccess, isLoading, isError } = useQuery(`getProject-${projectId}`, getProject);

    return { project: data, isSuccess, isLoading, isError };
}