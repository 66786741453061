
import { useQuery } from "react-query";
import { getData } from "./api";

const PORTFOLIO_PARAM = "Portafolio";

export const useGetPortfolio = () => {
    const { data, isSuccess, isLoading, isError } = useQuery('getPortfolio', () => getData(PORTFOLIO_PARAM));

    return { portfolio: data, isSuccess, isLoading, isError };
}