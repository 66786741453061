import React, { useEffect, useState } from 'react';
import {
    Grid,
    Box,
    Typography,
    ImageList,
    Card,
    ButtonBase,
    ImageListItem,
    Divider,
    CardActionArea,
    CardMedia,
    CardContent,
    CircularProgress,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { Link } from 'react-router-dom';
import star7 from '../assets/images/doodles/7star.svg';
import { useGetPortfolio } from '../hooks/useGetPortfolio';

const PortfolioFilters = ({ selectedFilters, onSelected }) => {
    const filters = [
        'Ilustración',
        'Animación 2D',
        'Videojuegos',
        'Editorial',
        'Concept'
    ]

    const getBoxStyle = (id) => (
        {
            display: 'inline-block',
            borderStyle: 'solid',
            borderColor: selectedFilters.includes(id) ? '#231E49' : 'transparent',
            borderWidth: 1,
            borderRadius: 200,
            px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3
            },
        }
    );

    return (
        <Grid
            container
            direction='row'
            justifyContent='center'
            gap={0.5}
            sx={{
                pb: 3
            }}
        >
            {filters.map((filter, index) => (
                <React.Fragment key={index}>
                    <Grid item key={filter}>
                        <ButtonBase
                            disableRipple={true}
                            variant='text'
                            onClick={() => onSelected(filter)}
                        >
                            <Box sx={getBoxStyle(filter)}>
                                <Typography
                                    variant='h6'
                                    noWrap={true}
                                    color='#231E49'
                                    sx={{
                                        fontFamily: 'CampaignLight',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontSize: '1.25rem'
                                    }}
                                >
                                    {filter}
                                </Typography>
                            </Box>
                        </ButtonBase>
                    </Grid>
                    {
                        index < filters.length - 1 && (
                            <Box
                                key={`${filter}-star`}
                                sx={{
                                    px: {
                                        xs: '0.5vw',
                                        sm: '0.5vw',
                                        md: '0.5vw',
                                        lg: '1vw',
                                        xl: '1vw'
                                    },
                                    display: 'flex'
                                }}
                            >
                                <Box
                                    component="img"
                                    src={star7}
                                    width={{
                                        xs: '15px',
                                        sm: '15px',
                                        md: '17.5px',
                                        lg: '20px',
                                        xl: '20px'
                                    }}
                                />
                            </Box>
                        )
                    }
                </React.Fragment>
            ))}
        </Grid >
    )
}

const ProjectImage = ({ project }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    return (
        <ImageListItem key={project.id}>
            <CardActionArea
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                sx={{
                    borderRadius: 10
                }}
            >
                <Link to={project.id} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Card
                        sx={{
                            borderRadius: 10,
                            boxShadow: 0
                        }}
                    >
                        {isHovered && (
                            <CardContent
                                sx={{
                                    borderRadius: 10,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'rgba(164, 148, 195, 0.75)',
                                    zIndex: 1
                                }}>
                                <Box
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        zIndex: 10
                                    }}
                                >
                                    <Typography
                                        variant='h5'
                                        color='white'
                                        textAlign='center'
                                        sx={{
                                            fontFamily: 'CampaignBlack',
                                            fontStyle: 'Italic',
                                            fontSize: {
                                                xs: '2rem',
                                                sm: '2rem',
                                                md: '2rem',
                                                lg: '2rem',
                                                xl: '2.25rem',
                                            },
                                        }}>
                                        {project.title}
                                        <br />
                                        <br />
                                    </Typography>
                                    <Typography
                                        variant='h8'
                                        color='white'
                                        textAlign='center'
                                        sx={{
                                            fontFamily: 'CampaignLight',
                                            fontSize: '1.5rem'
                                        }}>
                                        {project.subtitle}
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='white'
                                        textAlign='center'
                                        sx={{
                                            fontFamily: 'CampaignBlack',
                                            fontSize: '0.75rem',
                                            fontStyle: 'Italic',
                                            pt: 1
                                        }}>
                                        {project.tags.join(", ")}
                                    </Typography>
                                </Box>
                            </CardContent>
                        )}
                        <CardMedia
                            src={project.image}
                            image={project.image}
                            title={project.title}
                            sx={{
                                loading: "eager",
                                height: {
                                    xs: '50vw',
                                    sm: '50vw',
                                    md: '30vw',
                                    lg: '20vw',
                                    xl: '20vw',
                                },
                                width: 'auto',
                                position: 'relative',
                            }}
                        />
                    </Card>
                </Link>
            </CardActionArea>
        </ImageListItem>
    );
};

const ProjectsView = ({ projects }) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));

    return (
        <ImageList
            cols={isSm ? 1 : isMd ? 2 : 3}
            gap={12}
            sx={{
                pb: 5,
                px: {
                    xs: 6,
                    sm: 6,
                    md: 3,
                    lg: 0,
                    xl: 0
                }
            }}>
            {projects.map((project, index) => (
                <ProjectImage key={index} project={project} />
            ))}
        </ImageList>
    )
}

export const Portfolio = () => {
    const { portfolio, isSuccess, isLoading, isError } = useGetPortfolio();
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [visibleProjects, setVisibleProjects] = useState([]);

    const onSelectedFilter = (selectedFilter) => {
        if (selectedFilters.includes(selectedFilter)) {
            setSelectedFilters(prevFilters => prevFilters.filter(filter => filter !== selectedFilter));
        } else {
            setSelectedFilters(prevFilters => [...prevFilters, selectedFilter]);
        }
    }

    useEffect(() => {
        if (isSuccess) {
            if (selectedFilters.length) {
                setVisibleProjects(
                    portfolio.filter(project =>
                        selectedFilters.some(selectedFilter =>
                            project.tags.includes(selectedFilter)
                        )
                    )
                );
            } else {
                setVisibleProjects(portfolio);
            }
        }
    }, [selectedFilters, isSuccess, portfolio]);

    return (
        <Grid
            container
            direction='column'
            rowSpacing={2}
        >
            <Grid item xs='auto'>
                <PortfolioFilters selectedFilters={selectedFilters} onSelected={onSelectedFilter} />
            </Grid>
            <Divider flexItem
                sx={{
                    borderWidth: 1,
                    borderBlockColor: "#231E49"
                }}
            />
            <Grid item>
                {isSuccess ?
                    <ProjectsView projects={visibleProjects} />
                    : <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            py: 10,
                        }}
                    >
                        {isLoading && <CircularProgress />}
                        {isError && <p>Ha habido un error cargando los proyectos</p>}
                    </Box>
                }
            </Grid>
        </Grid >
    )
}